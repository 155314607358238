export const darkTheme = {
    palette: {
        primary: {
            main: '#f04e55',
        },
        secondary: {
            main: '#b9511a',
        },
        background: {
            default: '#111',
        },
        mode: 'dark' as 'dark', // Switching the dark mode on is a single property value change.
    },
    sidebar: {
        width: 250,
    },
    components: {
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    borderLeft: '3px solid #000',
                    '&.RaMenuItemLink-active': {
                        borderLeft: '3px solid #90caf9',
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: 'none',
                },
                root: {
                    border: '0px',
                    borderRadius: 20,
                    backgroundColor: '#222'
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                colorSecondary: {
                    color: '#ffffffb3',
                    backgroundColor: '#616161e6',
                }
            },
        },
        MuiButton: {
            styleOverrides: {
                outlined: {
                    border: "1px solid #ddd3",
                    color: "#fff",
                    borderRadius: 15,
                    '&:hover': {
                        border: "1px solid #ddd3",
                        color: 'white',
                    },
                },
                contained: {
                    color: "#fff",
                    background: "#2e2e2d",
                    borderRadius: 5,
                    fontSize: 12,
                    '&:hover': {
                        color: '#222',
                    },
                },
                text: {
                    color: "#f04e55",
                    padding: 7,
                    fontSize: 14,
                    '&:hover': {
                        color: 'rgb(242 113 33)',
                    },
                }
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    marginBottom: 10,
                    borderRadius: 30,
                    backgroundColor: "#222",
                    color: "gray"
                },
                icon: {
                    color: "#ddd"
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#222"
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#ddd",
                    fontSize: 13,
                    marginTop: 3
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    color: "#ddd"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    backgroundColor: "#1010105e !important",
                    padding: 20,
                    borderBottom: "none !important"
                }
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1010105e'
                }
            }
        },
    },
};

export const lightTheme = {
    palette: {
        primary: {
            main: '#393838',
        },
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
        background: {
            default: '#fff',
        },
        mode: 'light' as 'light',
    },
    shape: {
        borderRadius: 0,
    },
    sidebar: {
        width: 250,
    },
    components: {
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    borderLeft: '3px solid #fff',
                    '&.RaMenuItemLink-active': {
                        borderLeft: '3px solid #4f3cc9',
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: 'none',
                },
                root: {
                    border: '0px solid #e0e0e3',
                    backgroundClip: 'padding-box',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                colorSecondary: {
                    color: '#ddd',
                    backgroundColor: '#fff',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    background: "#ddd",
                    borderRadius: 20
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#222",
                    fontSize: 13,
                    marginTop: 3
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: "#222",
                    borderRadius: 10,
                    "& > fieldset": {
                        border: "1px solid #303030",
                        borderRadius: 20
                    },
                    '&:hover': {
                        borderColor: "red"
                    },
                }
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: '#f5f5f5',
                },
                barColorPrimary: {
                    backgroundColor: '#d7d7d7',
                },
            },
        },
    },
};