import React, { useRef, FC, useCallback } from "react";
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface Props {
    title: string;
    goTo: string;
}

const CreateButtonComponent: FC<Props> = ({ title, goTo }) => {

    return (
        <Box>
            <Button sx={{ backgroundColor: "#333" }} startIcon={<AddIcon />} variant="contained" href={`/#/${goTo}`}>{title}</Button>
        </Box>
    );

};

export default CreateButtonComponent;