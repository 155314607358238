import React, { FC } from 'react';
import { useNotify, useRefresh, useCreate, useGetOne, useUpdate } from 'react-admin';
import { useNavigate, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useMediaQuery, Theme, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';

const Edit: FC = () => {

    const navigate = useNavigate();
    const notify = useNotify();

    const { id } = useParams();

    const [update, { isLoading, error, data }] = useUpdate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const { data: driversDetails, isLoading: isLoadingDriversDetails, error: errorDriversDetails, refetch } = useGetOne(
        "drivers",
        {
            id: id
        }
    )

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const onSubmit = async (data: any) => {

        update('drivers', { data: data, id: id });

    };

    if (data) {
        notify("Driver updated successfully", { type: "success" });
        navigate("/drivers");
    }

    if (error) {
        notify("Driver was not updated successfully", { type: "error" });
    }

    React.useEffect(() => {

        if (driversDetails) {
            setValue("firstname", driversDetails.firstname);
            setValue("lastname", driversDetails.lastname);
            setValue("email", driversDetails.email);
        }

    }, [driversDetails])

    return (
        <Box sx={{ pt: 6 }}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={8}>
                    <Typography sx={{ fontSize: 20, color: "#ddd", mb: 2 }}>Edit Driver</Typography>
                </Grid>
            </Grid>
            <Box sx={{ m: 3 }} />
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item md={2} xs={6}>
                        <TextField
                            autoComplete="off"
                            id="firstname"
                            label="First Name *"
                            placeholder="First Name"
                            InputLabelProps={{
                                shrink: true
                            }}
                            {...register("firstname", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <TextField
                            autoComplete="off"
                            id="lastname"
                            label="Last Name *"
                            InputLabelProps={{
                                shrink: true
                            }}
                            placeholder="Last Name"
                            {...register("lastname", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }} />
                <Grid container spacing={2}>
                    <Grid item md={3} xs={8}>
                        <TextField
                            autoComplete="off"
                            id="email"
                            label="Email *"
                            type="email"
                            placeholder="Email"
                            InputLabelProps={{
                                shrink: true
                            }}
                            {...register("email", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 5 }} />
                <Grid container spacing={2}>
                    <Grid item md={1} xs={3}>
                        <Button
                            variant="text"
                            size="large"
                            onClick={() => navigate("/drivers")}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item md={1} xs={4}>
                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            fullWidth
                        >
                            {isLoading && (
                                <CircularProgress
                                    sx={{ mr: 1.5 }}
                                    size={25}
                                    thickness={2}
                                />
                            )}
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Edit;