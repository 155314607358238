import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Grid, Typography } from '@mui/material';
import { useLogout, usePermissions } from 'react-admin';

// components
import AnalyticsCard from './components/AnalyticsCard';
import DriverRefuelRequests from './components/DriverRefuelRequests';
import ActiveTasks from './components/ActiveTasks';

const Analytics = () => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const { permissions } = usePermissions();

    return (
        <Box sx={{ mt: isSmall ? 0 : 5 }}>
            {permissions && permissions.user_role != "systemadmin" &&
                <>
                    <Box sx={{ mt: 2 }}>
                        <Grid
                            container
                            spacing={isSmall ? 1.5 : 3}
                        >
                            <Grid item md={4} xs={12}>
                                <AnalyticsCard type="general_info" />
                            </Grid>
                            <Grid item md={2} xs={6}>
                                <AnalyticsCard type="refuelings" />
                            </Grid>
                            <Grid item md={2} xs={6}>
                                <AnalyticsCard type="expenses" />
                            </Grid>
                            <Grid item md={2} xs={6}>
                                <AnalyticsCard type="incomings" />
                            </Grid>
                            <Grid item md={2} xs={6}>
                                <AnalyticsCard type="services" />
                            </Grid>
                        </Grid>
                    </Box>
                    {permissions && permissions.user_role == "driver" &&
                        <Box sx={{ mt: isSmall ? 1.5 : 3 }}>
                            {/* <Grid
                                container
                                spacing={3}
                            >
                                <Grid item md={4}>
                                    <ActiveTasks />
                                </Grid>
                            </Grid> */}
                        </Box>
                    }
                    {permissions && permissions.user_role == "owner" && permissions.settings_type == "company" &&
                        <Box sx={{ mt: isSmall ? 1.5 : 3 }}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid item md={3} xs={12}>
                                    <DriverRefuelRequests />
                                </Grid>
                            </Grid>
                        </Box>
                    }
                </>
            }
        </Box>
    );

};

export default Analytics;