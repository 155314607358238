import * as React from 'react';
import { Box, Typography, useMediaQuery, Theme, Avatar } from '@mui/material';
import { usePermissions } from 'react-admin';

// import components
import SwitchVehicles from './components/SwitchVehicles';
import IndividualMenu from './components/IndividualMenu';
import CompanyMenu from './components/CompanyMenu';
import SystemAdminMenu from './components/SystemAdminMenu';
import DriverMenu from './components/DriverMenu';

const Menu = () => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const { permissions } = usePermissions();

    return (
        <Box sx={{ height: "100%", backgroundColor: "#111", mt: isSmall ? 0 : 2, p: 3 }}>
            {permissions &&
                <>
                    {permissions.user_role != "systemadmin" &&
                        <SwitchVehicles />
                    }
                    {permissions.settings_type == "individual" && permissions.user_role == "owner" &&
                        <IndividualMenu />
                    }
                    {permissions.settings_type == "company" && permissions.user_role == "driver" &&
                        <DriverMenu />
                    }
                    {permissions.settings_type == "company" && permissions.user_role == "owner" &&
                        <CompanyMenu />
                    }
                    {permissions.user_role == "systemadmin" &&
                        <SystemAdminMenu />
                    }
                </>
            }
        </Box>
    )

}

export default Menu;