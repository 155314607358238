import React, { useRef, FC, useCallback } from "react";
import { Box, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

interface Props {
    title: string;
    goTo: string;
}

const EditButtonComponent: FC<Props> = ({ title, goTo }) => {

    return (
        <IconButton sx={{ p: 2 }} href={goTo}>
            <EditIcon sx={{ color: "#ddd" }} />
        </IconButton>
    );

};

export default EditButtonComponent;