import * as React from 'react';
import { Toolbar, AppBar, Box, useMediaQuery, Theme, Tooltip, IconButton, Menu, MenuItem, ListItemIcon, Divider, Typography, Avatar } from '@mui/material';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useLogout, usePermissions, useSidebarState } from 'react-admin';
import { useNavigate } from "react-router-dom";
import PersonAdd from '@mui/icons-material/PersonAdd';
import { googleLogout } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import MenuIcon from '@mui/icons-material/Menu';

// import components
import Logo from '../components/Logo';
import Wave from '../components/Wave';
import TimelineLarge from './components/TimelineLarge';
import AvatarAccount from './components/AvatarAccount';
import InfoAccount from './components/InfoAccount';

const CustomAppBar = (props: any) => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const logout = useLogout();
    const [sidebarOpen, toggleSidebar] = useSidebarState();

    const token = localStorage.getItem("access_token");

    const { permissions } = usePermissions();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const goToSubscriptions = () => {
        navigate("/subscriptions");
    }

    const goToSettings = () => {
        navigate("/settings");
    }

    const logoutapp = () => {
        googleLogout();
        logout();
    }

    return (
        <>
            <AppBar sx={{ borderRadius: 0 }} elevation={0} {...props}>
                <Toolbar>
                    {!isSmall ?
                        <Box sx={{ ml: 0, width: 10, mt: 1 }}>
                            <Logo />
                        </Box>
                        :
                        <Box>
                            <IconButton onClick={() => toggleSidebar(!sidebarOpen)}>
                                <MenuIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                        </Box>
                    }
                    {isSmall &&
                        <>
                            <Box sx={{ flexGrow: 0.85 }} />
                            <Box sx={{ width: 10, mt: 1 }}>
                                <Logo />
                            </Box>
                        </>
                    }
                    {permissions && permissions.user_role != "systemadmin" && !isSmall ?
                        <>
                            <Box sx={{ transform: 'scaleX(-1)', marginBottom: -2 }}>
                                <Wave />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", marginBottom: -1.2, backgroundColor: "#111" }}>
                                <TimelineLarge />
                            </Box>
                            <Box sx={{ transform: 'scaleX(1)', marginBottom: -2 }}>
                                <Wave />
                            </Box>
                        </>
                        :
                        <Box sx={{ flexGrow: 1 }} />
                    }
                    <Box>
                        <Box>
                            <Tooltip title="Account settings">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    {token &&
                                        <AvatarAccount />
                                    }
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    width: 280,
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: '#222',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <Box sx={{ p: 3 }}>
                                {token &&
                                    <>
                                        <AvatarAccount />
                                        <InfoAccount />
                                    </>
                                }
                            </Box>
                            <Divider />
                            {permissions && permissions.user_role != "driver" &&
                                <MenuItem sx={{ height: 40 }} onClick={goToSubscriptions}>
                                    <ListItemIcon>
                                        <PersonAdd fontSize="small" />
                                    </ListItemIcon>
                                    Subscriptions
                                </MenuItem>
                            }
                            {permissions && permissions.user_role != "systemadmin" &&
                                <MenuItem sx={{ height: 40 }} onClick={goToSettings}>
                                    <ListItemIcon>
                                        <Settings fontSize="small" />
                                    </ListItemIcon>
                                    Settings
                                </MenuItem>
                            }
                            <MenuItem sx={{ height: 40 }} onClick={logoutapp}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    )

}

export default CustomAppBar;