import * as React from 'react';
import { useNotify, useRefresh, useGetList, useGetOne, useDataProvider, useTheme } from 'react-admin';
import { Box, Dialog, DialogContent, Button, useMediaQuery, Theme, ListItem, ListItemButton, List, Stack, Typography, TextField, InputAdornment, Pagination } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from "react-router-dom";

const SwitchVehicles = () => {

    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();
    const navigate = useNavigate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const [currentPage, setCurrentPage] = React.useState(1);
    const [q, setQ] = React.useState("");

    const { data, isLoading, error } = useGetOne(
        'settings-vehicle-selected/selected',
        {
            id: ""
        }
    );

    const { data: vehicles, total, isLoading: isLoadingVehicles, error: errorVehicles } = useGetList(
        'vehicles',
        {
            pagination: { page: 1, perPage: 23 },
            sort: { field: 'date', order: 'DESC' },
            filter: {
                q: q
            }
        }
    );

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePageChange = (event: any, value: any) => {
        setCurrentPage(value);
    }

    const selectVehicle = async (record: any) => {

        await dataProvider.update<any>(
            "settings-vehicle-selected/switch",
            {
                id: "",
                data: {
                    vehicle_id: record.id,
                    select_all: false
                },
                previousData: {
                    id: ""
                }
            }
        ).then((response) => {
            setOpen(false);
            refresh();
        }).catch((error) => {
            notify(error.message);
        })

    }

    return (
        <>
            <Box component="a" onClick={handleClickOpen} sx={{ textDecoration: "none", cursor: "pointer", color: "#ddd" }}>
                <Stack direction="row" spacing={1.5}>
                    <Box sx={{ alignSelf: "center", mt: 1 }}>
                        <img width={25} src={data?.vehicle.make.logo} />
                    </Box>
                    <Box sx={{ alignSelf: "center" }}>
                        <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>{data?.vehicle.make.name}</Typography>
                        <Typography sx={{ fontSize: 11, color: "gray" }} >{data?.vehicle.license_plate}</Typography>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ pr: 1.5 }}>
                        <KeyboardArrowDownIcon sx={{ color: "gray" }} />
                    </Box>
                </Stack>
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        width: 500,
                        height: "100%",
                        backgroundColor: "#000",
                        color: "white"
                    }
                }}
                maxWidth="lg"
            >
                <DialogContent>
                    <Box>
                        <Typography>Switch Vehicles</Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            id="q"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            size="small"
                            onChange={(e) => setQ(e.target.value)}
                            placeholder="Search ..."
                        />
                    </Box>
                    <Box sx={{ mt: 2, mb: 2 }}>
                        {vehicles?.map((record: any, index: number) => {
                            return (
                                <Box sx={{ display: "flex" }}>
                                    <Box sx={{ width: "100%" }}>
                                        <Button
                                            startIcon={<img src={record.make.logo} width={30} />}
                                            sx={{
                                                backgroundColor: "#333",
                                                borderRadius: 5,
                                                color: "#fff",
                                                textTransform: "capitalize",
                                                justifyContent: "left",
                                                width: "100%",
                                                p: 2,
                                                mb: 0.5,
                                                display: "flex"
                                            }}
                                            onClick={() => selectVehicle(record)}
                                        >
                                            {record.make.name + " " + record.make_model}
                                        </Button>
                                    </Box>
                                </Box>
                            );
                        })}
                        <Box>
                            {vehicles?.length === 0 &&
                                <Box>
                                    <Typography>No results found</Typography>
                                </Box>
                            }
                        </Box>
                        {total as number > 10 &&
                            <Box sx={{ mt: 2 }}>
                                <Pagination page={currentPage} onChange={handlePageChange} count={Math.ceil(total as any / 10)} variant="outlined" shape="rounded" />
                            </Box>
                        }
                        <Box sx={{ mt: 2 }}>
                            <Button variant="contained" color="success" sx={{ width: 200, height: 42, borderRadius: 10 }} onClick={() => {
                                navigate("/vehicles/create");
                                setOpen(false);
                            }}>Add New</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default SwitchVehicles;