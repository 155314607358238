import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { useMediaQuery, Theme, Typography } from '@mui/material';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PaidIcon from '@mui/icons-material/Paid';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import AddIcon from '@mui/icons-material/Add';
import { useGetOne } from 'react-admin';
import { useNavigate } from "react-router-dom";
import ContentLoader from 'react-content-loader';

// import components
import NumberFormat from '../../../components/other/NumberFormat';
import Price from '../../../components/other/Price';
import InfoAccount from '../../../layout/components/InfoAccount';

interface Props {
    type: string
}

const AnalyticsCard: FC<Props> = ({ type }: any) => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const { data, isLoading, error } = useGetOne('analytics/quick', { id: "" });

    const navigate = useNavigate();

    const onClickRefueling = () => {
        navigate("/refueling/create");
    }

    const onClickExpense = () => {
        navigate("/expenses/create");
    }

    const onClickService = () => {
        navigate("/service/create");
    }

    const onClickIncome = () => {
        navigate("/incomings/create");
    }

    const onClickReminder = () => {
        navigate("/reminders/create");
    }

    return (
        <>
            {isLoading ?
                <ContentLoader
                    width="100%"
                    height={250}
                    backgroundColor="#222"
                    foregroundColor="#333"
                    style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}
                >
                    <rect x="0" y="0" rx="3" ry="3" width="100%" height="250" />
                </ContentLoader>
                :
                <>
                    {type == "general_info" &&
                        <Box sx={{ backgroundColor: "#222", width: "100%", height: isSmall ? 200 : 250, borderTopLeftRadius: 20, borderTopRightRadius: 20, p: 3 }}>
                            <Typography fontSize={12} mb={-1} color="gray">Welcome</Typography>
                            <InfoAccount showemail={false} />
                            <Box sx={{ mt: 1 }}>
                                <Typography color="gray" fontSize={14} mb={1}>Quick navigation</Typography>
                                <Box sx={{ display: "flex" }}>
                                    <Box component="a" onClick={onClickRefueling} sx={{ width: 60, height: 60, textAlign: "center", pt: 1, '&:hover': { backgroundColor: "#444", cursor: "pointer" }, backgroundColor: "#333", mr: isSmall ? 0.3 : 1, borderRadius: 2 }}>
                                        <AddIcon sx={{ fontSize: 25, color: "#FFA000" }} />
                                        <Typography sx={{ mt: -0.5, color: "#FFA000" }} fontSize={9}>Refueling</Typography>
                                    </Box>
                                    <Box component="a" onClick={onClickExpense} sx={{ width: 60, height: 60, textAlign: "center", pt: 1, '&:hover': { backgroundColor: "#444", cursor: "pointer" }, backgroundColor: "#333", mr: isSmall ? 0.3 : 1, borderRadius: 2 }}>
                                        <AddIcon sx={{ fontSize: 25, color: "#00838F" }} />
                                        <Typography sx={{ mt: -0.5, color: "#00838F" }} fontSize={9}>Expense</Typography>
                                    </Box>
                                    <Box component="a" onClick={onClickService} sx={{ width: 60, height: 60, textAlign: "center", pt: 1, '&:hover': { backgroundColor: "#444", cursor: "pointer" }, backgroundColor: "#333", mr: isSmall ? 0.3 : 1, borderRadius: 2 }}>
                                        <AddIcon sx={{ fontSize: 25, color: "#C0CA33" }} />
                                        <Typography sx={{ mt: -0.5, color: "#C0CA33" }} fontSize={9}>Service</Typography>
                                    </Box>
                                    <Box component="a" onClick={onClickIncome} sx={{ width: 60, height: 60, textAlign: "center", pt: 1, '&:hover': { backgroundColor: "#444", cursor: "pointer" }, backgroundColor: "#333", mr: isSmall ? 0.3 : 1, borderRadius: 2 }}>
                                        <AddIcon sx={{ fontSize: 25 }} />
                                        <Typography sx={{ mt: -0.5 }} fontSize={9}>Income</Typography>
                                    </Box>
                                    <Box component="a" onClick={onClickReminder} sx={{ width: 60, height: 60, textAlign: "center", pt: 1, '&:hover': { backgroundColor: "#444", cursor: "pointer" }, backgroundColor: "#333 ", mr: isSmall ? 0.3 : 1, borderRadius: 2 }}>
                                        <AddIcon sx={{ fontSize: 25, color: "#f04e55" }} />
                                        <Typography sx={{ mt: -0.5, color: "#f04e55" }} fontSize={9}>Reminder</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    }
                    {type == "refuelings" &&
                        <Box sx={{ backgroundColor: "#222", width: "100%", height: isSmall ? 200 : 250, borderTopLeftRadius: 20, borderTopRightRadius: 20, p: 3 }}>
                            <Box>
                                <LocalGasStationIcon sx={{ fontSize: 65, color: "#FFA000", ml: -1 }} />
                                {data?.saved_analytics &&
                                    <Typography fontSize={13} color="#ddd">Based on {data?.saved_analytics.filter_type_title}</Typography>
                                }
                                <Typography fontSize={isSmall ? 15 : 20} color="#ddd" fontWeight="bold">Refuelings</Typography>
                                <Box sx={{ display: "flex" }}>
                                    <Price price={data?.refueling._sum.total_cost ? data?.refueling._sum.total_cost : 0} base_currency="ALL" colors="#fff" size={isSmall ? 18 : 24} />
                                    <Typography sx={{ ml: 1 }} fontSize={isSmall ? 18 : 24} fontWeight="bold">ALL</Typography>
                                </Box>
                            </Box>
                        </Box>
                    }
                    {type == "expenses" &&
                        <Box sx={{ backgroundColor: "#222", width: "100%", height: isSmall ? 200 : 250, borderTopLeftRadius: 20, borderTopRightRadius: 20, p: 3 }}>
                            <Box>
                                <AccountBalanceWalletIcon sx={{ fontSize: 65, color: "#00838F", ml: -1 }} />
                                {data?.saved_analytics &&
                                    <Typography fontSize={13} color="#ddd">Based on {data?.saved_analytics.filter_type_title}</Typography>
                                }
                                <Typography fontSize={isSmall ? 15 : 20} color="#ddd" fontWeight="bold">Expenses</Typography>
                                <Box sx={{ display: "flex" }}>
                                    <Price price={data?.expense._sum.total_cost ? data?.expense._sum.total_cost : 0} base_currency="ALL" colors="#fff" size={isSmall ? 18 : 24} />
                                    <Typography sx={{ ml: 1 }} fontSize={isSmall ? 18 : 24} fontWeight="bold">ALL</Typography>
                                </Box>
                            </Box>
                        </Box>
                    }
                    {type == "incomings" &&
                        <Box sx={{ backgroundColor: "#222", width: "100%", height: isSmall ? 200 : 250, borderTopLeftRadius: 20, borderTopRightRadius: 20, p: 3 }}>
                            <Box>
                                <PaidIcon sx={{ fontSize: 65, color: "#fff", ml: -1 }} />
                                {data?.saved_analytics &&
                                    <Typography fontSize={13} color="#ddd">Based on {data?.saved_analytics.filter_type_title}</Typography>
                                }
                                <Typography fontSize={isSmall ? 15 : 20} color="#ddd" fontWeight="bold">Incomings</Typography>
                                <Box sx={{ display: "flex" }}>
                                    <Price price={data?.incomings._sum.total_cost ? data?.incomings._sum.total_cost : 0} base_currency="ALL" colors="#fff" size={isSmall ? 18 : 24} />
                                    <Typography sx={{ ml: 1 }} fontSize={isSmall ? 18 : 24} fontWeight="bold">ALL</Typography>
                                </Box>
                            </Box>
                        </Box>
                    }
                    {type == "services" &&
                        <Box sx={{ backgroundColor: "#222", width: "100%", height: isSmall ? 200 : 250, borderTopLeftRadius: 20, borderTopRightRadius: 20, p: 3 }}>
                            <Box>
                                <MinorCrashIcon sx={{ fontSize: 65, color: "#C0CA33", ml: -1 }} />
                                {data?.saved_analytics &&
                                    <Typography fontSize={13} color="#ddd">Based on {data?.saved_analytics.filter_type_title}</Typography>
                                }
                                <Typography fontSize={isSmall ? 15 : 20} color="#ddd" fontWeight="bold">Services</Typography>
                                <Box sx={{ display: "flex" }}>
                                    <Price price={data?.service._sum.total_cost ? data?.service._sum.total_cost : 0} base_currency="ALL" colors="#fff" size={isSmall ? 18 : 24} />
                                    <Typography sx={{ ml: 1 }} fontSize={isSmall ? 18 : 24} fontWeight="bold">ALL</Typography>
                                </Box>
                            </Box>
                        </Box>
                    }
                </>
            }
        </>
    );

};

export default AnalyticsCard;