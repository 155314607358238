import React, { FC } from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    useListContext,
    useNotify,
    useCreate,
    Pagination,
    ListBase,
    CreateButton,
    ExportButton
} from 'react-admin';
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, Grid } from '@mui/material';
import { useMediaQuery, Theme, Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { format, addHours } from 'date-fns';
import AddTaskIcon from '@mui/icons-material/AddTask';

// import components
import ColumnList from './ColumnList';
import LockCreate from '../../components/buttons/LockCreate';
import SelectableFilter from '../../components/inputs/SelectableFilter';

const ResourceList = () => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const ListActions = ({ isSmall }: any) => (
        <Box sx={{ pt: 2.5, pb: 1 }}>
            <LockCreate title="Add New" goTo="vehicles/create" type="vehicles" source_count="vehicles_count" source_limit="vehicles_limit" />
        </Box>
    );

    const [selectedIds, setSelectedIds] = React.useState<number[]>([]);

    const bulkIds = (data: any) => {
        setSelectedIds(data);
    }

    const BulkActions = ({ isSmall }: any) => {

        const navigate = useNavigate();
        const notify = useNotify();

        const [create, { isLoading, error, data }]: any = useCreate();

        const [selectedGroupItem, setSelectedGroup] = React.useState<any>({});

        const [open, setOpen] = React.useState(false);

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        const selectedGroup = (data: any) => {
            setSelectedGroup(data);
        }

        const confirm = () => {

            create('vehicle-group-assigned', {
                data: {
                    vehicles_ids: selectedIds,
                    vehicle_groups_id: selectedGroupItem.id
                }
            })

            handleClose();
            setSelectedIds([]);

        }

        return (
            <Box sx={{ pt: 2.5, pb: 1 }}>
                <Button sx={{ backgroundColor: "#f04e55", color: "#fff" }} startIcon={<AddIcon />} variant="contained" onClick={handleClickOpen}>Assign to group</Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            width: 600,
                            backgroundColor: "#000",
                            color: "white"
                        }
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Chosse group and assign your selected vehicles.
                        </DialogContentText>
                        <Box sx={{ width: 200, mt: 1 }}>
                            <SelectableFilter resource="vehicle-groups" source="group_name" title="Vehicle group" selected={selectedGroup} />
                        </Box>
                        <DialogActions sx={{ justifyContent: "center", mt: 1 }}>
                            <Button onClick={confirm}>
                                Confirm
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </Box>
        )
    };

    return (
        <Box sx={{ pt: isSmall ? 3 : 7 }}>
            <ListBase perPage={12} sort={{ field: 'reference', order: 'ASC' }}>
                <Box sx={{ backgroundColor: "#222", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                    {selectedIds.length > 0 ?
                        <Box sx={{ p: 1, pl: 3, pr: 3, backgroundColor: "#333", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                            <Box sx={{ display: "flex" }}>
                                <Box sx={{ alignSelf: "center" }}>
                                    <Typography fontSize={20} color="#ddd">Vehicles</Typography>
                                </Box>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box sx={{ mt: -1.5 }}>
                                    <BulkActions />
                                </Box>
                            </Box>
                        </Box>
                        :
                        <Box sx={{ p: 1, pl: 3, pr: 3 }}>
                            <Box sx={{ display: "flex" }}>
                                <Box sx={{ alignSelf: "center" }}>
                                    <Typography fontSize={20} color="#ddd">Vehicles</Typography>
                                </Box>
                                <Box sx={{ flexGrow: 1 }} />
                                <Box sx={{ mt: -1.5 }}>
                                    <ListActions />
                                </Box>
                            </Box>
                        </Box>
                    }
                    <ColumnList bulkIds={bulkIds} />
                    <Box sx={{ p: 0.2 }}>
                        <Pagination rowsPerPageOptions={[12, 24, 48, 72]} />
                    </Box>
                </Box>
            </ListBase>
        </Box>
    )

}

export default ResourceList;