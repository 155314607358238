import { CustomRoutes, Admin, Resource } from 'react-admin';
import { Route } from 'react-router';
import { GoogleOAuthProvider } from '@react-oauth/google';

// import providers
import authProvider from './providers/AuthProvider';
import dataProvider from './providers/data-provider';

// import layout
import { Login, Layout } from './layout';
import { darkTheme } from './layout/themes';
import { Analytics } from './pages/analytics';

// import resources
import refueling from './pages/refueling';
import expenses from './pages/expenses';
import services from './pages/services';
import incoming from './pages/incoming';
import reminders from './pages/reminders';
import drivers from './pages/drivers';
import routes from './pages/routes';
import vehicles from './pages/vehicles';
import vehicleGroup from './pages/vehicle-group';
import tasks from './pages/tasks';
import vendors from './pages/vendors';
import Help from './pages/help/Help';
import Feedback from './pages/feedback/Feedback';
import expensesType from './pages/expenses-type';
import fuel from './pages/fuel';
import fuelTypes from './pages/fuel-types';
import serviceType from './pages/service-type';
import incomingsType from './pages/incomings-type';
import makes from './pages/makes';
import makeModel from './pages/make-model';
import plans from './pages/plans';
import addons from './pages/addons';
import subscriptions from './pages/subscriptions';

// import pages
import Settings from './pages/settings/Settings';
import Reports from './pages/reports/Reports';

const App = () => {

	return (
		<GoogleOAuthProvider clientId="59425894971-38m3kes8llhcgcv0tsphk49jmtc6h9p4.apps.googleusercontent.com">
			<Admin
				title="Auttom.io - Welcome to auttom"
				dataProvider={dataProvider}
				authProvider={authProvider}
				dashboard={Analytics}
				loginPage={Login}
				layout={Layout}
				disableTelemetry
				theme={darkTheme}
			>
				<CustomRoutes>
					<Route path="/reports" element={<Reports />} />
					<Route path="/settings" element={<Settings />} />
					<Route path="/help" element={<Help />} />
					<Route path="/feedback" element={<Feedback />} />
				</CustomRoutes>
				<Resource name="refueling" {...refueling} />
				<Resource name="expenses" {...expenses} />
				<Resource name="service" {...services} />
				<Resource name="incomings" {...incoming} />
				<Resource name="reminders" {...reminders} />
				<Resource name="drivers" {...drivers} />
				<Resource name="route" {...routes} />
				<Resource name="vehicles" {...vehicles} />
				<Resource name="vehicle-groups" {...vehicleGroup} />
				<Resource name="tasks" {...tasks} />
				<Resource name="vendors" {...vendors} />
				<Resource name="expenses-type" {...expensesType} />
				<Resource name="fuel" {...fuel} />
				<Resource name="fuel-types" {...fuelTypes} />
				<Resource name="service-type" {...serviceType} />
				<Resource name="incomings-type" {...incomingsType} />
				<Resource name="makes" {...makes} />
				<Resource name="make-model" {...makeModel} />
				<Resource name="plans" {...plans} />
				<Resource name="addons" {...addons} />
				<Resource name="subscriptions" {...subscriptions} />
			</Admin>
		</GoogleOAuthProvider>
	)

};

export default App;