import React, { FC, useState, useEffect, useCallback } from 'react';
import { useNotify, useRefresh, useUpdate, useGetOne } from 'react-admin';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import { RadioGroup } from "@mui/material";
import { CircularProgress } from '@mui/material';
import { useMediaQuery, Theme, InputLabel, MenuItem, Select } from '@mui/material';
import jwt_decode from "jwt-decode";
import { format, compareAsc } from 'date-fns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { useParams } from "react-router-dom";

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch sx={{ mr: 2 }} focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#ef4e55' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const Edit: FC = () => {

    const navigate = useNavigate();
    const notify = useNotify();
    const refresh = useRefresh();

    const { id } = useParams();

    const [update, { isLoading, error, data }] = useUpdate();

    const { data: settingsVehicleSelected, isLoading: isLoadingSettingsVehicleSelected, error: errorSettingsVehicleSelected } = useGetOne(
        'settings-vehicle-selected/selected',
        {
            id: ""
        }
    );

    const { data: remindersDetails, isLoading: isLoadingRemindersDetails, error: errorRemindersDetails, refetch } = useGetOne(
        "reminders",
        {
            id: id
        }
    )

    const [checkedOtherOptions, setCheckedOtherOptions] = React.useState(false);

    const [repeatType, setRepeatType] = useState("one_time");

    const [showDateCheckbox, setShowDateCheckbox] = useState(true);
    const [showTimeCheckbox, setShowTimeCheckbox] = useState(false);

    const [showDateInput, setShowDateInput] = useState(false);
    const [showAmountPeriodStartDateInput, setShowAmountPeriodStartDateInput] = useState(false);

    const [selectedPeriod, setSelectedPeriod] = useState<string>("day");

    const [dateChecked, setDateChecked] = useState(false);

    const onChangeDateCheckbox = (event: any) => {

        setDateChecked(event.target.checked);

        if (event.target.checked === true) {
            setShowDateInput(true)
        } else {
            setShowDateInput(false);
        }

    }

    const [timeChecked, setTimeChecked] = useState(false);

    const onChangeTimeCheckbox = (event: any) => {

        setTimeChecked(event.target.checked);

        if (event.target.checked === true) {
            setShowAmountPeriodStartDateInput(true)
        } else {
            setShowAmountPeriodStartDateInput(false);
        }

    }

    const [dateValue, setDateValue] = React.useState<Date | any>(
        new Date()
    );

    const [timeValue, setTimeValue] = React.useState<Date | any>(
        new Date()
    );

    const [startDateValue, setStartDateValue] = React.useState<Date | any>(
        new Date()
    );

    const [startTimeValue, setStartTimeValue] = React.useState<Date | any>(
        new Date()
    );

    const { register, watch, setValue, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data: any) => {

        data.vehicle_id = settingsVehicleSelected?.vehicle.id;

        data.repeat_type = repeatType;

        if (data.repeat_type == "one_time") {

            data.date_checkbox = dateChecked;

            if (dateChecked) {
                data.date = new Date(dateValue);
                data.time = new Date(timeValue);
            }

        } else {

            data.time_checkbox = timeChecked;

            if (timeChecked) {
                data.repeat_period = selectedPeriod;
                data.start_date = new Date(startDateValue);
                data.start_time = new Date(startTimeValue);
            }

        }

        update('reminders', { data: data, id: id });

    }

    React.useEffect(() => {

        if (repeatType == 'one_time') {
            setShowDateCheckbox(true);
            setShowTimeCheckbox(false);
            setShowDateInput(true);
            setShowAmountPeriodStartDateInput(false);
            setDateChecked(true);
            setTimeChecked(false);
        } else {
            setShowTimeCheckbox(true);
            setShowDateCheckbox(false);
            setShowDateInput(false);
            setShowAmountPeriodStartDateInput(true);
            setDateChecked(false);
            setTimeChecked(true);
        }


    }, [repeatType])

    React.useEffect(() => {

        if (remindersDetails) {

            setValue("name", remindersDetails?.name);
            setRepeatType(remindersDetails?.repeat_type);

            if (remindersDetails?.repeat_type == "one_time") {
                setDateChecked(remindersDetails?.date_checkbox);

                if (remindersDetails?.date_checkbox) {
                    setShowDateCheckbox(true);
                    setShowDateInput(true);
                    setDateValue(new Date(remindersDetails?.date));
                    setTimeValue(new Date(remindersDetails?.time));
                } else {
                    setShowDateInput(false);
                }

            } else {

                setShowDateCheckbox(false);
                setShowDateInput(false);

                setShowTimeCheckbox(true);

                setTimeChecked(remindersDetails?.time_checkbox);

                if (remindersDetails?.time_checkbox) {
                    setShowAmountPeriodStartDateInput(true);
                    setSelectedPeriod(remindersDetails?.repeat_period);
                    setStartDateValue(new Date(remindersDetails?.start_date));
                    setStartTimeValue(new Date(remindersDetails?.start_time));
                } else {
                    setShowAmountPeriodStartDateInput(false);
                }

            }

        }

    }, [remindersDetails])

    if (data) {
        notify("Reminder updated successfully", { type: "success" });
        navigate("/reminders");
        refresh();
    }

    if (error) {
        notify("Reminder was not updated successfully", { type: "error" });
    }

    return (
        <Box sx={{ pt: 6 }}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={8}>
                    <Typography sx={{ fontSize: 20, color: "#ddd" }}>Edit Reminder</Typography>
                </Grid>
            </Grid>
            <Box sx={{ m: 5 }} />
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <TextField
                            autoComplete="off"
                            id="name"
                            type="text"
                            label="Reminder name *"
                            placeholder="Reminder name *"
                            InputLabelProps={{
                                shrink: true
                            }}
                            {...register("name", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }} />
                <Grid container spacing={2}>
                    <Grid item md={2} xs={6}>
                        <Radio
                            checked={repeatType === "one_time"}
                            value="one_time"
                            color="default"
                            sx={{ display: 'none' }}
                        />
                        <Button
                            onClick={() => setRepeatType("one_time")}
                            sx={{ padding: 1.2, alignItems: "center", width: "100%", backgroundColor: repeatType === "one_time" ? "#ef4e55" : "#222" }}
                        >
                            <Typography style={{ color: "#ddd", fontSize: 16 }}>
                                One Time
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <Radio
                            checked={repeatType === "repeat_every"}
                            value="repeat_every"
                            color="default"
                            sx={{ display: 'none' }}
                        />
                        <Button
                            onClick={() => setRepeatType("repeat_every")}
                            sx={{ padding: 1.2, alignItems: "center", width: "100%", backgroundColor: repeatType === "repeat_every" ? "#ef4e55" : "#222" }}
                        >
                            <Typography style={{ color: "#ddd", fontSize: 16 }}>
                                Repeat
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Box sx={{ m: 5 }} />
                <Grid container spacing={10}>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item md={1} xs={5}>
                                {showDateCheckbox &&
                                    <FormGroup>
                                        <FormControlLabel
                                            onChange={(e) => onChangeDateCheckbox(e)}
                                            control={
                                                <IOSSwitch checked={dateChecked} sx={{ ml: 2 }} name="date_check" />
                                            }
                                            label="Date"
                                        />
                                    </FormGroup>
                                }
                                {showTimeCheckbox &&
                                    <FormGroup>
                                        <FormControlLabel
                                            onChange={(e) => onChangeTimeCheckbox(e)}
                                            control={
                                                <IOSSwitch checked={timeChecked} sx={{ ml: 2 }} name="time_check" />
                                            }
                                            label="Time"
                                        />
                                    </FormGroup>
                                }
                            </Grid>
                        </Grid>
                        <Box sx={{ m: 4 }} />
                        <Grid container spacing={2}>
                            {showDateInput &&
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Grid item md={5} xs={6}>
                                        <MobileDatePicker
                                            label="Date *"
                                            value={dateValue}
                                            onChange={(newValue: any) => {
                                                setDateValue(newValue);
                                            }}
                                            renderInput={(params: any) => <TextField fullWidth {...params} />}
                                        />
                                    </Grid>
                                    <Grid item md={4} xs={6}>
                                        <MobileTimePicker
                                            label="Time *"
                                            value={timeValue}
                                            onChange={(newValue: any) => {
                                                setTimeValue(newValue);
                                            }}
                                            renderInput={(params: any) => <TextField fullWidth {...params} />}
                                        />
                                    </Grid>
                                </LocalizationProvider>
                            }
                            {showAmountPeriodStartDateInput &&
                                <>
                                    <Grid item md={3} xs={5}>
                                        <FormControl fullWidth>
                                            <InputLabel id="repeat-period">Period</InputLabel>
                                            <Select
                                                labelId="repeat-period"
                                                sx={{ backgroundColor: "#333", borderRadius: 5 }}
                                                id="repeat-period"
                                                value={selectedPeriod}
                                                label="Period"
                                                onChange={(value) => {
                                                    setSelectedPeriod(value.target.value);
                                                }}
                                            >
                                                <MenuItem value="day">Day</MenuItem>
                                                <MenuItem value="month">Month</MenuItem>
                                                <MenuItem value="year">Year</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Grid item md={3.5} xs={6}>
                                            <MobileDatePicker
                                                label="Start Date *"
                                                inputFormat="DD/MM/YYYY"
                                                value={startDateValue}
                                                onChange={(newValue: any) => {
                                                    setStartDateValue(newValue);
                                                }}
                                                renderInput={(params: any) => <TextField fullWidth {...params} />}
                                            />
                                        </Grid>
                                        <Grid item md={3} xs={6}>
                                            <MobileTimePicker
                                                label="Start Time *"
                                                value={startTimeValue}
                                                onChange={(newValue: any) => {
                                                    setStartTimeValue(newValue);
                                                }}
                                                renderInput={(params: any) => <TextField fullWidth {...params} />}
                                            />
                                        </Grid>
                                    </LocalizationProvider>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }} />
                <Grid container spacing={2}>
                    <Grid item md={12} xs={20}>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={15}>
                                    <Checkbox
                                        checked={checkedOtherOptions}
                                        edge="start"
                                        sx={{ display: "none" }}
                                    />
                                    <Button
                                        startIcon={checkedOtherOptions ? <RemoveIcon /> : <AddIcon />}
                                        onClick={() => { setCheckedOtherOptions((check: any) => !check) }}
                                        sx={{
                                            color: "gray",
                                            fontSize: 15,
                                            pl: 2,
                                            pr: 2
                                        }}
                                    >
                                        More Options
                                    </Button>
                                </Grid>
                            </Grid>
                            {checkedOtherOptions &&
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item md={5} xs={12}>
                                            <TextField
                                                autoComplete="off"
                                                sx={{ mt: 4 }}
                                                id="notes"
                                                label="Notes"
                                                placeholder="Notes (Optional)"
                                                {...register("notes", { required: false })}
                                                variant="outlined"
                                                multiline
                                                rows={4}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ m: 5 }} />
                <Grid container spacing={2}>
                    <Grid item md={1} xs={3}>
                        <Button
                            variant="text"
                            size="large"
                            onClick={() => navigate("/reminders")}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item md={1} xs={4}>
                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            fullWidth
                        >
                            {isLoading && (
                                <CircularProgress
                                    sx={{ mr: 1.5 }}
                                    size={25}
                                    thickness={2}
                                />
                            )}
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )
}

export default Edit;