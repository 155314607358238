import React, { FC, useState, useEffect } from 'react';
import { useNotify, useRefresh, useUpdate } from 'react-admin';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import { Divider } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import Chip from '@mui/material/Chip';

interface Props {
    details: any;
}

const CompanySettings: FC<Props> = ({ details }) => {

    const notify = useNotify();

    const [update, { isLoading, error, data }] = useUpdate();

    const [state, setState] = React.useState(false);

    const toggleDrawerOpen = () => {
        setState(true);
    };

    const toggleDrawerClose = () => {
        setState(false);
    };

    const { register, watch, setValue, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (record: any) => {
        update('settings', {
            data: {
                name: record.name
            },
            id: details?.id
        });
    };

    if (data) {
        notify("Your settings updated successfully", { type: "success" });
    }

    if (error) {
        notify("Your settings was not updated successfully", { type: "error" });
    }

    useEffect(() => {
        if (details) {
            setValue("name", details?.name);
        }
    }, [details]);

    return (
        <>
            <Box>
                <Avatar sx={{ bgcolor: deepOrange[500] }} variant="rounded">
                    <BusinessIcon />
                </Avatar>
            </Box>
            <Box>
                <Typography sx={{ fontSize: 18, color: "#ddd", mt: -0.5 }}>Company Settings</Typography>
                <Typography sx={{ fontSize: 11, color: "gray", mb: 1 }}>Change your company settings</Typography>
                <Button variant="text" color="warning" startIcon={<EditIcon />} onClick={toggleDrawerOpen}>
                    {details?.name}
                </Button>
            </Box>
            <Drawer
                anchor="right"
                open={state}
                onClose={toggleDrawerClose}
                PaperProps={{
                    style: {
                        borderRadius: 0,
                        backgroundColor: "#000"
                    }
                }}
            >
                <Box sx={{ p: 5, width: 350 }}>
                    <BusinessIcon sx={{ color: "white", fontSize: 45 }} />
                    <Typography sx={{ color: "white", fontSize: 20, mt: 1 }}>Company Settings</Typography>
                    <Box sx={{ mt: 1, mb: 1 }}></Box>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            <Grid item md={9}>
                                <Chip sx={{ backgroundColor: "green", color: "white" }} label="Company" />
                            </Grid>
                        </Grid>
                        <Box sx={{ m: 5 }}>
                            <Divider />
                        </Box>
                        <Grid container spacing={3}>
                            <Grid item md={12}>
                                <TextField
                                    autoComplete="off"
                                    id="name"
                                    type="text"
                                    label="Company Name *"
                                    placeholder="Company Name"
                                    {...register("name")}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ m: 5 }}></Box>
                        <Grid container spacing={2}>
                            <Grid item md={5}>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => setState(false)}
                                    fullWidth
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item md={5}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    size="large"
                                    fullWidth
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Drawer>
        </>
    );

};

export default CompanySettings;