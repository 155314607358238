import React, { FC, useEffect } from 'react';
import { useNotify, useRefresh, useUpdate } from 'react-admin';
import { Box, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { green } from '@mui/material/colors';
import EuroIcon from '@mui/icons-material/Euro';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import EditIcon from '@mui/icons-material/Edit';
import Drawer from '@mui/material/Drawer';
import FormLabel from '@mui/material/FormLabel';
import jwt_decode from "jwt-decode";

interface Props {
    details: any;
}

const Currency: FC<Props> = ({ details }) => {

    const notify = useNotify();

    const [update, { isLoading, error, data }] = useUpdate();

    const [state, setState] = React.useState(false);

    const toggleDrawerOpen = () => {
        setState(true);
    };

    const toggleDrawerClose = () => {
        setState(false);
    };

    const [value, setValue] = React.useState("");

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const onSubmit = async () => {
        update('settings', {
            data: {
                currency: value
            },
            id: details?.id
        });
    };

    if (data) {
        notify("Your settings updated successfully", { type: "success" });
    }

    if (error) {
        notify("Your settings was not updated successfully", { type: "error" });
    }

    useEffect(() => {
        setValue(details?.currency);
    }, [state])

    return (
        <>
            <Box>
                <Avatar sx={{ bgcolor: green[500] }} variant="rounded">
                    <EuroIcon />
                </Avatar>
            </Box>
            <Box>
                <Typography sx={{ fontSize: 18, color: "#ddd", mt: -0.5 }}>Currency</Typography>
                <Typography sx={{ fontSize: 11, color: "gray", mb: 1 }}>Change your currency settings</Typography>
                <Button variant="text" color="warning" startIcon={<EditIcon />} onClick={toggleDrawerOpen} >
                    {details?.currency}
                </Button>
            </Box>
            <Drawer
                anchor="right"
                open={state}
                onClose={toggleDrawerClose}
                PaperProps={{
                    style: {
                        borderRadius: 0,
                        backgroundColor: "#000"
                    }
                }}
            >
                <Box sx={{ p: 5, width: 350 }}>
                    <EuroIcon sx={{ color: "white", fontSize: 45 }} />
                    <Typography sx={{ color: "white", fontSize: 20, mt: 1 }}>Currency</Typography>
                    <Box sx={{ m: 5 }}>
                        <Divider />
                    </Box>
                    <FormControl>
                        <FormLabel sx={{ color: "gray", mb: 1, fontSize: 19 }}>Change currency ...</FormLabel>
                        <RadioGroup
                            name="currency"
                            value={value}
                            onChange={handleRadioChange}
                            sx={{ color: "white" }}
                        >
                            <FormControlLabel
                                value="EUR"
                                control={
                                    <Radio
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 34
                                            }
                                        }}
                                    />
                                }
                                label="Euro (EUR)"
                            />
                            <FormControlLabel
                                value="ALL"
                                control={
                                    <Radio
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 34,
                                            },
                                        }}
                                    />
                                }
                                label="Albanian (ALL)"
                            />
                            <FormControlLabel
                                value="GBP"
                                control={
                                    <Radio
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 34,
                                            },
                                        }}
                                    />
                                }
                                label="Pound (GBP)"
                            />
                            <FormControlLabel
                                value="USD"
                                control={
                                    <Radio
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 34,
                                            },
                                        }}
                                    />
                                }
                                label="Dollar (USD)"
                            />
                        </RadioGroup>
                    </FormControl>
                    <Box sx={{ m: 5 }}></Box>
                    <Grid container spacing={2}>
                        <Grid item md={5}>
                            <Button
                                variant="outlined"
                                type="button"
                                onClick={() => setState(false)}
                                fullWidth
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item md={5}>
                            <Button
                                variant="contained"
                                type="button"
                                onClick={onSubmit}
                                size="large"
                                fullWidth
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Drawer>
        </>
    );

};

export default Currency;