import React, { FC, useState, useEffect, useCallback } from 'react';
import { useNotify, useRefresh, useCreate, useGetOne } from 'react-admin';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useMediaQuery, Theme, Switch, SwitchProps, CircularProgress, FormControl, FormGroup, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import jwt_decode from "jwt-decode";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch sx={{ mr: 2 }} focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#9e3c11' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const Create: FC = () => {

    const navigate = useNavigate();
    const notify = useNotify();

    const [create, { isLoading, error, data }] = useCreate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const [selectedType, setSelectedType] = useState<any>("individual");

    const onChangePlanType = (event: any) => {
        setSelectedType(event.target.defaultValue);
    }

    const [active, setActive] = useState(false);

    const onChangedActive = (event: any) => {
        setActive(event.target.checked);
    }

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data: any) => {
        data.type = selectedType;
        data.driver_limit = JSON.parse(data.driver_limit);
        data.reminder_limit = JSON.parse(data.reminder_limit);
        data.vehicle_limit = JSON.parse(data.vehicle_limit);
        data.price = JSON.parse(data.price);
        data.active = active;
        create('plans', { data: data });
    };

    if (data) {
        notify("Your plan added successfully", { type: "success" });
        navigate("/plans");
    }

    if (error) {
        notify("Your plan was not added successfully", { type: "error" });
    }

    return (
        <Box sx={{ pt: 6 }}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={8}>
                    <Typography sx={{ fontSize: 20, color: "#ddd", mb: 2 }}>Add plan</Typography>
                </Grid>
            </Grid>
            <Box sx={{ m: 3 }} />
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={10}>
                        <TextField
                            autoComplete="off"
                            id="name"
                            label="Name *"
                            placeholder="Name"
                            {...register("name", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }} />
                <Grid container spacing={2}>
                    <Grid item md={6} xs={10}>
                        <TextField
                            autoComplete="off"
                            id="description"
                            label="Description *"
                            placeholder="Description"
                            rows={5}
                            multiline
                            {...register("description", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }} />
                <Grid container spacing={2}>
                    <Grid item md={2} xs={10}>
                        <TextField
                            autoComplete="off"
                            id="vehicle_limit"
                            label="Vehicles Limit *"
                            type="number"
                            placeholder="Vehicles Limit"
                            {...register("vehicle_limit", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={2} xs={10}>
                        <TextField
                            autoComplete="off"
                            id="reminder_limit"
                            label="Reminders Limit *"
                            type="number"
                            placeholder="Reminders Limit"
                            {...register("reminder_limit", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={2} xs={10}>
                        <TextField
                            autoComplete="off"
                            id="driver_limit"
                            label="Drivers Limit *"
                            type="number"
                            placeholder="Drivers Limit"
                            {...register("driver_limit", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }} />
                <Grid container spacing={2}>
                    <Grid item md={3} xs={10}>
                        <TextField
                            autoComplete="off"
                            id="price"
                            label="Price *"
                            placeholder="Price"
                            {...register("price", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }} />
                <Grid container spacing={2}>
                    <Grid item md={6} xs={8}>
                        <FormControl>
                            <RadioGroup
                                onChange={(e) => onChangePlanType(e)}
                                row
                                defaultValue="individual"
                                aria-labelledby="type"
                                name="type"
                            >
                                <FormControlLabel value="individual" control={<Radio />} label="Individual" />
                                <FormControlLabel value="company" control={<Radio />} label="Company" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }} />
                <Grid container spacing={2}>
                    <Grid item md={3} xs={10}>
                        <TextField
                            autoComplete="off"
                            id="product_id_google_play"
                            label="Google Play Product ID"
                            placeholder="Google Play Product ID"
                            {...register("product_id_google_playstore", { required: false })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={3} xs={10}>
                        <TextField
                            autoComplete="off"
                            id="product_id_apple_appstore"
                            label="Apple App Store Product ID"
                            placeholder="Apple App Store Product ID"
                            {...register("product_id_apple_appstore", { required: false })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }}></Box>
                <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                        <FormControlLabel
                            control={<IOSSwitch onChange={(e: any) => onChangedActive(e)} sx={{ ml: 2 }} name="active" />}
                            label="Active"
                        />
                    </FormGroup>
                </FormControl>
                <Box sx={{ m: 5 }}>
                    <Divider />
                </Box>
                <Grid container spacing={2}>
                    <Grid item md={1} xs={3}>
                        <Button
                            variant="text"
                            size="large"
                            onClick={() => navigate("/plans")}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item md={1} xs={4}>
                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            fullWidth
                        >
                            {isLoading && (
                                <CircularProgress
                                    sx={{ mr: 1.5 }}
                                    size={25}
                                    thickness={2}
                                />
                            )}
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )

}

export default Create;