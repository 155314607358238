import { Box, Avatar } from '@mui/material';
import { useGetOne } from 'react-admin';
import jwt_decode from "jwt-decode";

const AvatarAccount = () => {

    const token = localStorage.getItem("access_token");
    let decode: any = jwt_decode(`${token}`);
    let user_id = decode['user']['id'];

    const { data: users, isLoading: isLoadingUsers, error: errorUsers } = useGetOne('users', { id: user_id });

    return (
        <Avatar sx={{ fontSize: 15, width: 35, height: 35, backgroundColor: "orange", fontWeight: "bold" }}>
            {users && users?.firstname || users?.lastname ? users?.firstname.charAt(0) + "" + users?.lastname.charAt(0) : ""}
        </Avatar>
    );

};

export default AvatarAccount;