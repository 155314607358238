import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { ListItemButton, List, ListItemText, DialogTitle, Typography, Dialog, Button, DialogContent } from '@mui/material';

interface Props {
    changeType: any;
}

const Type: FC<Props> = ({ changeType }) => {

    const reporttypes = [
        // {
        //     id: 1,
        //     title: "General",
        //     type: "general"
        // },
        {
            id: 2,
            title: "Refuelings",
            type: "refuelings"
        },
        {
            id: 3,
            title: "Incomes",
            type: "incomings"
        },
        {
            id: 4,
            title: "Expenses",
            type: "expenses"
        },
        {
            id: 5,
            title: "Services",
            type: "services"
        }
    ]

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [selectedTitle, setSelectedTitle] = React.useState("Refuelings");
    const [selectedType, setSelectedType] = React.useState("refuelings");

    const handleListItemClick = (
        type: string,
        title: string,
        index: number,
    ) => {
        setSelectedType(type);
        setSelectedTitle(title);
        changeType(type);
        handleClose();
    };

    return (
        <Box>
            <Button onClick={handleClickOpen} variant="contained">{selectedTitle}</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        width: 400,
                        backgroundColor: "#000"
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    Report Type
                </DialogTitle>
                <DialogContent>
                    <List component="nav" aria-label="main mailbox folders">
                        {reporttypes.map((item, index) => (
                            <ListItemButton
                                selected={selectedType == item.type}
                                onClick={(event) => handleListItemClick(item.type, item.title, index)}
                            >
                                <ListItemText primary={item.title} />
                            </ListItemButton>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
        </Box>
    );

};

export default Type;