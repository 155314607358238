import React, { FC } from 'react';
import { useNotify, useRefresh, useUpdate } from 'react-admin';
import { Box, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { lightBlue } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';

interface Props {
    details: any
}

const AccountSettings: FC<Props> = ({ details }) => {

    const notify = useNotify();

    const [update, { isLoading, error, data }] = useUpdate();

    const [state, setState] = React.useState(false);

    const toggleDrawerOpen = () => {
        setState(true);
    };

    const toggleDrawerClose = () => {
        setState(false);
    }

    const { register, watch, setValue, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data: any) => {
        update('users', {
            data: {
                firstname: data.firstname,
                lastname: data.lastname
            },
            id: details?.id
        });
    }

    if (data) {
        notify("User details updated successfully", { type: "success" });
    }

    if (error) {
        notify("User details was not updated successfully", { type: "error" });
    }

    React.useEffect(() => {
        if (details) {
            setValue("firstname", details?.firstname);
            setValue("lastname", details?.lastname);
            setValue("email", details?.email);
        }
    }, [details])

    return (
        <>
            <Box>
                <Avatar sx={{ bgcolor: lightBlue[500] }} variant="rounded">
                    <AccountCircleIcon />
                </Avatar>
            </Box>
            <Box>
                <Typography sx={{ fontSize: 18, color: "#ddd", mt: -0.5 }}>Account Settings</Typography>
                <Typography sx={{ fontSize: 11, color: "gray", mb: 1 }}>Change your account settings</Typography>
                <Button variant="text" color="warning" startIcon={<AccountCircleIcon />} onClick={toggleDrawerOpen}>
                    {details?.firstname} {details?.lastname}
                </Button>
            </Box>
            <Drawer
                anchor="right"
                open={state}
                onClose={toggleDrawerClose}
                PaperProps={{
                    style: {
                        borderRadius: 0,
                        backgroundColor: "#000"
                    }
                }}
            >
                <Box sx={{ p: 5, width: 350 }}>
                    <AccountCircleIcon sx={{ color: "white", fontSize: 45 }} />
                    <Typography sx={{ color: "white", fontSize: 20, mt: 1 }}>Account Settings</Typography>
                    <Box sx={{ mt: 1, mb: 1 }}></Box>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            <Grid item md={9}>
                                <Chip sx={{ backgroundColor: "green", color: "white" }} label="Owner" />
                            </Grid>
                        </Grid>
                        <Box sx={{ m: 5 }}>
                            <Divider />
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <TextField
                                    autoComplete="off"
                                    id="firstname"
                                    type="text"
                                    label="First Name *"
                                    placeholder="First Name"
                                    {...register("firstname")}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    autoComplete="off"
                                    id="lastname"
                                    type="text"
                                    label="Last Name *"
                                    placeholder="Last Name"
                                    {...register("lastname")}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ m: 2 }}></Box>
                        <Grid container spacing={3}>
                            <Grid item md={9}>
                                <TextField
                                    autoComplete="off"
                                    id="email"
                                    type="email"
                                    label="Email *"
                                    placeholder="Email"
                                    {...register("email")}
                                    disabled={true}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ m: 5 }}></Box>
                        <Grid container spacing={2}>
                            <Grid item md={5}>
                                <Button
                                    variant="outlined"
                                    type="button"
                                    onClick={() => setState(false)}
                                    fullWidth
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item md={5}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    size="large"
                                    fullWidth
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Drawer>
        </>
    );

};

export default AccountSettings;