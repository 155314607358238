import React, { useRef, FC, useCallback } from "react";
import { Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNotify, useDelete } from 'react-admin';

interface Props {
    title: string;
    resource: string;
    id: number;
}

const DeleteButtonComponent: FC<Props> = ({ title, resource, id }) => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [deleteOne, { isLoading, error }] = useDelete(
        resource,
        {
            id: id,
            previousData: {
                id: id
            }
        }
    );

    const handleConfirm = () => {
        deleteOne();
        setOpen(false);
    }

    return (
        <>
            <IconButton sx={{ p: 2 }} onClick={handleClickOpen}>
                <DeleteIcon sx={{ color: "red" }} />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-description">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to delete {id}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        size="large"
                        onClick={handleClose}
                        fullWidth
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        size="large"
                        onClick={handleConfirm} autoFocus
                        fullWidth
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

};

export default DeleteButtonComponent;