import * as React from 'react';
import { Divider, Stack } from '@mui/material';
import { Box, Typography, Avatar, Button } from '@mui/material';
import { useCreatePath, useGetOne, useListContext } from 'react-admin';
import { Link } from 'react-router-dom';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { Grid, CardContent, Card, CardActions } from '@mui/material';
import { format, addHours } from 'date-fns';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import NumberFormat from '../../components/other/NumberFormat';
import DeleteButtonComponent from '../../components/buttons/Delete';

const LoadedGridList = () => {

    const { data } = useListContext();
    if (!data) return null;

    return (
        <>
            <Box sx={{ backgroundColor: "#1010105e", p: 0.2 }} />
            <Grid sx={{ p: 2 }} container spacing={2}>
                {data?.map((record: any) => (
                    <Grid item md={2} xs={6}>
                        <Box>
                            <Card sx={{ backgroundColor: "#222" }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: "bold" }} >
                                        {record.group_name}
                                    </Typography>
                                    <Box>
                                        <Typography sx={{ fontSize: 14, color: "gray" }}>{record.vehicle_group_assigned.length} vehicles</Typography>
                                    </Box>
                                </CardContent>
                                <CardActions sx={{ alignItems: "center", justifyContent: "center" }}>
                                    <IconButton href={`/#/vehicle-groups/${record.id}`}>
                                        <EditIcon />
                                    </IconButton>
                                    <DeleteButtonComponent title="Delete" resource="vehicle-groups" id={record.id} />
                                </CardActions>
                            </Card>
                        </Box>
                    </Grid>
                ))}
                <Grid item md={12}>
                    {data?.length == 0 &&
                        <Box sx={{ textAlign: "center", p: 20 }}>
                            <HourglassEmptyIcon sx={{ color: "gray", fontSize: 85 }} />
                            <Typography color="gray" fontSize={18}>You don't have any reminder.</Typography>
                            <Button variant="contained" href={`/#/vehicle-groups/create`} sx={{ mt: 1, fontSize: 12 }}>Add Group</Button>
                        </Box>
                    }
                </Grid>
            </Grid >
        </>
    );
};

const GridList = () => {
    const { isLoading } = useListContext();
    return <LoadedGridList />;
}

export default GridList;