import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { Dialog, ListItem, ListItemIcon, TextField, InputAdornment, DialogContent, Button, Grid, List, Checkbox, Pagination, Typography, ListItemButton, Divider, ListItemText, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNotify, useDelete, useGetList, useCreate, useDataProvider, useTheme } from 'react-admin';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
    resource: string;
    title: string;
    source: string;
    selected: any;
    createMode?: boolean;
    deleteMode?: boolean;
    createResource?: string;
    createSource?: string;
    onEditItemSelected?: any;
}

const SelectableFilter: FC<Props> = ({ resource, title, source, selected, createMode = false, deleteMode = false, createResource, createSource, onEditItemSelected }) => {

    const [create, { isLoading, error, data: dataCreate }] = useCreate();

    const [deleteOne, { isLoading: isLoadingOne, error: errorDelete, data: dataDelete }] = useDelete();

    const [currentPage, setCurrentPage] = React.useState(1);
    const [q, setQ] = React.useState("");

    const { data: dataList, total, isLoading: isLoadingList, error: errorList, refetch } = useGetList(
        resource,
        {
            pagination: { page: currentPage, perPage: 10 },
            sort: { field: 'date', order: 'DESC' },
            filter: {
                q: q
            }
        }
    );

    const [selectedItem, setSelectedItem] = React.useState<any>({});
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [value, setValue] = React.useState("");
    const [showAddInput, setShowAddInput] = React.useState(false);

    const onChangeAdd = (e: any) => {
        setValue(e.target.value);
    }

    const selectedRecord = (record: any) => {
        setSelectedItem(record);
        selected(record);
        setOpen(false);
    }

    const handlePageChange = (event: any, value: any) => {
        setCurrentPage(value);
    }

    const deleteItem = (record: any) => {
        deleteOne(
            resource,
            { id: record.id, previousData: record }
        );
    }

    const onSubmit = () => {
        create(createResource, {
            data: {
                [`${createSource}`]: value
            }
        })
    }

    if (dataCreate) {
        refetch();
    }

    if (dataDelete) {
        refetch();
    }

    React.useEffect(() => {

        if (onEditItemSelected) {
            setSelectedItem(onEditItemSelected);
        }

    }, [onEditItemSelected])

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    <Button
                        onClick={handleClickOpen}
                        startIcon={selectedItem && selectedItem[`${source}`] != undefined ? <EditIcon /> : <AddIcon />}
                        sx={{
                            backgroundColor: "#333",
                            color: "#fff",
                            fontSize: 12,
                            width: "100%",
                            height: 45,
                            borderRadius: 4,
                            pl: 2,
                            textTransform: "capitalize",
                            pr: 2,
                            mt: 1
                        }}
                        size="large"
                    >
                        {selectedItem && selectedItem[`${source}`] != undefined ? selectedItem[`${source}`] : title}
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                        width: 500,
                        height: "100%",
                        backgroundColor: "#000",
                        color: "white"
                    }
                }}
                maxWidth="lg"
            >
                <DialogContent>
                    <Box>
                        <Typography>{title}</Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            id="q"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            size="small"
                            onChange={(e) => setQ(e.target.value)}
                            placeholder="Search ..."
                        />
                    </Box>
                    <List sx={{ width: '100%', maxWidth: 500 }}>
                        {dataList?.map((record: any, index: number) => {

                            return (
                                <ListItem
                                    key={record.id}
                                    secondaryAction={
                                        <>
                                            {deleteMode &&
                                                <Box sx={{ alignSelf: "center", display: "flex" }}>
                                                    {/* <IconButton sx={{ backgroundColor: "#333" }}>
                                                        <EditIcon sx={{ color: "#fff" }} />
                                                    </IconButton> */}
                                                    <IconButton disabled={record.settings_id == 1} sx={{ backgroundColor: "#333", ml: 1 }} onClick={() => deleteItem(record)}>
                                                        <DeleteIcon sx={{ color: record.settings_id == 1 ? "#444" : "#f04e55" }} />
                                                    </IconButton>
                                                </Box>
                                            }
                                        </>
                                    }
                                >
                                    <ListItemButton sx={{ ml: -2 }} role={undefined} onClick={() => selectedRecord(record)}>
                                        {/* {record[`${source}`] == selectedItem[`${source}`] &&
                                            <ListItemIcon>
                                                <CheckIcon sx={{ color: "#f04e55" }} />
                                            </ListItemIcon>
                                        } */}
                                        <ListItemText primary={record[`${source}`]} />
                                    </ListItemButton>
                                </ListItem>
                            );

                        })}
                        <Box>
                            {dataList?.length === 0 &&
                                <Box>
                                    <Typography>No results found</Typography>
                                </Box>
                            }
                        </Box>
                        {total as number > 10 &&
                            <Box sx={{ mt: 2 }}>
                                <Pagination page={currentPage} onChange={handlePageChange} count={Math.ceil(total as any / 10)} variant="outlined" shape="rounded" />
                            </Box>
                        }
                    </List>
                    {createMode &&
                        <Box sx={{ mt: 3 }}>
                            {showAddInput ?
                                <Grid container spacing={1}>
                                    <Grid item md={8}>
                                        <TextField
                                            type="text"
                                            onChange={(e) => onChangeAdd(e)}
                                            label="Add New"
                                            fullWidth
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item md={2} display="flex">
                                        <Button variant="text" onClick={onSubmit}>Add</Button>
                                        <Button variant="text" sx={{ color: "gray" }} onClick={() => setShowAddInput(false)}>Cancel</Button>
                                    </Grid>
                                </Grid>
                                :
                                <Box>
                                    <Button variant="contained" color="success" sx={{ width: 200, height: 42, borderRadius: 10 }} onClick={() => setShowAddInput(true)}>Add New</Button>
                                </Box>
                            }
                        </Box>
                    }
                </DialogContent>
            </Dialog>
        </Box>
    );

};

export default SelectableFilter;