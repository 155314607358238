import { SVGProps } from 'react';
import { useTheme } from '@mui/material/styles';

const WaveRight = (props: SVGProps<SVGSVGElement>) => {
    const theme = useTheme();
    return (
        <svg width="170px" height="54px" viewBox="0 0 170 54" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path d="M0,-6.70644096e-15 C6.83796258,-0.0296688684 13.5046293,-0.0296688684 20,-7.35175809e-15 C29.7430561,0.0445033026 34.495911,0.402106709 38.8672419,1.13091721 C43.6142154,1.92235675 47.8204354,3.18800982 52.0076279,5.0848639 L52.7853326,5.44277762 C56.4518479,7.15867693 59.8327361,9.1725968 67.5202605,14.5941973 L68.7705389,15.4790116 L101.229461,38.5243652 C110.384353,45.0242026 113.80518,47.0216588 117.992372,48.9185129 C121.970205,50.7205242 125.96521,51.9528767 130.424703,52.7501674 L131.132758,52.8724596 C135.583046,53.6144341 139.615607,53.9622502 150.357815,54 C157.519287,54.0251665 164.066682,54.0251665 170,54 L0,54 L0,-6.70644096e-15 Z" fill={theme?.palette?.mode === 'dark' ? "#111" : "#fff"} fill-rule="nonzero"></path>
        </svg>
    );
};

export default WaveRight;