import React, { FC } from 'react';
import { useNotify, useRefresh, useCreate } from 'react-admin';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useMediaQuery, Theme, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch sx={{ mr: 2 }} focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#9e3c11' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const Create: FC = () => {

    const navigate = useNavigate();
    const notify = useNotify();

    const [create, { isLoading, error, data }] = useCreate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const [requestMode, setRequestMode] = React.useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data: any) => {
        data.request_mode = requestMode;
        create('drivers', { data: data });
    };

    if (data) {
        notify("Driver added successfully", { type: "success" });
        navigate("/drivers");
    }

    if (error) {
        notify("This user exists, please check your email", { type: "info" });
    }

    return (
        <Box sx={{ pt: 6 }}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={8}>
                    <Typography sx={{ fontSize: 20, color: "#ddd", mb: 2 }}>Add Driver</Typography>
                </Grid>
            </Grid>
            <Box sx={{ m: 3 }} />
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item md={2} xs={6}>
                        <TextField
                            autoComplete="off"
                            id="firstname"
                            label="First Name *"
                            placeholder="First Name"
                            {...register("firstname", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <TextField
                            autoComplete="off"
                            id="lastname"
                            label="Last Name *"
                            placeholder="Last Name"
                            {...register("lastname", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }} />
                <Grid container spacing={2}>
                    <Grid item md={3} xs={8}>
                        <TextField
                            autoComplete="off"
                            id="email"
                            label="Email *"
                            type="email"
                            placeholder="Email"
                            {...register("email", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 3 }} />
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <FormControlLabel
                            control={<IOSSwitch onChange={(e: any) => setRequestMode(e.target.checked)} sx={{ ml: 2 }} name="request_mode" />}
                            label="Request Mode"
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 5 }} />
                <Grid container spacing={2}>
                    <Grid item md={1} xs={3}>
                        <Button
                            variant="text"
                            size="large"
                            onClick={() => navigate("/drivers")}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item md={1} xs={4}>
                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            fullWidth
                        >
                            {isLoading && (
                                <CircularProgress
                                    sx={{ mr: 1.5 }}
                                    size={25}
                                    thickness={2}
                                />
                            )}
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default Create;