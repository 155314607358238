import React, { FC, useEffect } from 'react';
import { useNotify, useRefresh, useUpdate } from 'react-admin';
import { Box, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { brown } from '@mui/material/colors';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import BuildIcon from '@mui/icons-material/Build';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import Drawer from '@mui/material/Drawer';
import FormLabel from '@mui/material/FormLabel';

interface Props {
    details: any
}

const Unit: FC<Props> = ({ details }) => {

    const notify = useNotify();

    const [update, { isLoading, error, data }] = useUpdate();

    const [state, setState] = React.useState(false);

    const toggleDrawerOpen = () => {
        setState(true);
    };

    const toggleDrawerClose = () => {
        setState(false);
    };

    const [unitFuelValue, setUnitFuelValue] = React.useState("");

    const handleRadioChangeUnitFuel = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUnitFuelValue((event.target as HTMLInputElement).value);
    };

    const [unitDistanceValue, setUnitDistanceValue] = React.useState("");

    const handleRadioChangeUnitDistance = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUnitDistanceValue((event.target as HTMLInputElement).value);
    };

    const onSubmit = async () => {
        update('settings', {
            data: {
                unit_fuel: unitFuelValue,
                unit_distance: unitDistanceValue
            },
            id: details?.id
        });
    };

    if (data) {
        notify("Your settings updated successfully", { type: "success" });
    }

    if (error) {
        notify("Your settings was not updated successfully", { type: "error" });
    }

    useEffect(() => {
        setUnitFuelValue(details?.unit_fuel);
        setUnitDistanceValue(details?.unit_distance);
    }, [state])

    return (
        <>
            <Box>
                <Avatar sx={{ bgcolor: brown[500] }} variant="rounded">
                    <BuildIcon />
                </Avatar>
            </Box>
            <Box>
                <Typography sx={{ fontSize: 18, color: "#ddd", mt: -0.5 }}>Unit</Typography>
                <Typography sx={{ fontSize: 11, color: "gray", mb: 1 }}>Configure your unit settings</Typography>
                <Button variant="text" color="warning" startIcon={<EditIcon />} onClick={toggleDrawerOpen}>
                    {details?.unit_distance} / {details?.unit_fuel}
                </Button>
            </Box>
            <Drawer
                anchor="right"
                open={state}
                onClose={toggleDrawerClose}
                PaperProps={{
                    style: {
                        borderRadius: 0,
                        backgroundColor: "#000"
                    }
                }}
            >
                <Box sx={{ p: 5, width: 350 }}>
                    <BuildIcon sx={{ color: "white", fontSize: 40 }} />
                    <Typography sx={{ color: "white", fontSize: 20, mt: 1 }}>Unit Settings</Typography>
                    <Box sx={{ m: 5 }}>
                        <Divider />
                    </Box>
                    <FormControl>
                        <FormLabel sx={{ color: "gray", mb: 1, fontSize: 19 }}>Change unit of fuel ...</FormLabel>
                        <RadioGroup
                            name="unit-fuel"
                            value={unitFuelValue}
                            onChange={handleRadioChangeUnitFuel}
                            sx={{ color: "white" }}
                        >
                            <FormControlLabel
                                value="L"
                                control={
                                    <Radio
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 34
                                            }
                                        }}
                                    />
                                }
                                label="Litre (L)"
                            />
                            <FormControlLabel
                                value="GAL"
                                control={
                                    <Radio
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 34,
                                            },
                                        }}
                                    />
                                }
                                label="Gallon (Gal)"
                            />
                        </RadioGroup>
                    </FormControl>
                    <Box sx={{ m: 3 }}></Box>
                    <FormControl>
                        <FormLabel sx={{ color: "gray", mb: 1, fontSize: 19 }}>Change unit of distance ...</FormLabel>
                        <RadioGroup
                            name="unit-distance"
                            value={unitDistanceValue}
                            onChange={handleRadioChangeUnitDistance}
                            sx={{ color: "white" }}
                        >
                            <FormControlLabel
                                value="KM"
                                control={
                                    <Radio
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 34
                                            }
                                        }}
                                    />
                                }
                                label="Kilometer (Km)"
                            />
                            <FormControlLabel
                                value="MI"
                                control={
                                    <Radio
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 34,
                                            },
                                        }}
                                    />
                                }
                                label="Mile (Mi)"
                            />
                        </RadioGroup>
                    </FormControl>
                    <Box sx={{ m: 5 }}></Box>
                    <Grid container spacing={2}>
                        <Grid item md={5}>
                            <Button
                                variant="outlined"
                                type="button"
                                onClick={() => setState(false)}
                                fullWidth
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item md={5}>
                            <Button
                                variant="contained"
                                type="button"
                                onClick={onSubmit}
                                size="large"
                                fullWidth
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Drawer>
        </>
    );

};

export default Unit;