import React, { useRef, FC, useCallback } from "react";
import { Box, Theme, Typography } from '@mui/material';

interface Props {
    value: any
    colors?: any
    size?: any
}

const NumberFormat: FC<Props> = ({ value, colors, size }) => {

    return (
        <Box>
            <Typography style={{ fontSize: size ? size : 14, color: colors ? colors : "gray", fontWeight: "bold", marginRight: 4 }}>{value?.toLocaleString('en-US', { minimumFractionDigits: 0 })} Km</Typography>
        </Box>
    )

};

export default NumberFormat;