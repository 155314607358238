import React, { FC, useState, useEffect, useCallback } from 'react';
import { useNotify, useRefresh, useDataProvider, useGetOne, useUpdate } from 'react-admin';
import { useNavigate, useParams } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useMediaQuery, Theme } from '@mui/material';
import jwt_decode from "jwt-decode";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Checkbox from '@mui/material/Checkbox';

//components
import SelectableFilterWithValue from '../../components/inputs/SelectableFilterWithValue';

const Edit: FC = () => {

    const navigate = useNavigate();
    const notify = useNotify();
    const refresh = useRefresh();

    const { id } = useParams();

    const [update, { isLoading, error, data }] = useUpdate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const { data: settingsVehicleSelected, isLoading: isLoadingSettingsVehicleSelected, error: errorSettingsVehicleSelected } = useGetOne(
        'settings-vehicle-selected/selected',
        {
            id: ""
        }
    );

    const [checkedOtherOptions, setCheckedOtherOptions] = React.useState(false);

    const [expenseValues, setExpenseValues] = useState([]);

    const [dateValue, setDateValue] = React.useState<Dayjs | null>(
        dayjs(new Date())
    );

    const [timeValue, setTimeValue] = React.useState<Dayjs | null>(
        dayjs(new Date())
    );

    const getListExpenseValues = (data: any) => {
        setExpenseValues(data);
    }

    const { data: expenseDetails, isLoading: isLoadingExpenseDetails, error: errorExpenseDetails, refetch } = useGetOne(
        "expenses",
        {
            id: id
        }
    )

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const onSubmit = async (data: any) => {

        data.vehicle_id = settingsVehicleSelected?.vehicle.id;
        data.date = dateValue;
        data.time = timeValue;
        data.odometer = parseFloat(data.odometer);
        data.expense_type_list = expenseValues;

        update('expenses', { data: data, id: id });

    };

    if (data) {
        notify("Expense updated successfully", { type: "success" });
        navigate("/expenses");
        refresh();
    }

    if (error) {
        notify("Expense was not updated successfully", { type: "error" });
    }

    React.useEffect(() => {

        if (expenseDetails) {
            setValue("odometer", expenseDetails?.odometer);
            setValue("notes", expenseDetails?.notes);
            setDateValue(expenseDetails?.date);
            setTimeValue(expenseDetails?.time);
            setExpenseValues(expenseDetails?.expense_type_values);
        }

    }, [expenseDetails])

    return (
        <Box sx={{ pt: 6 }}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={8}>
                    <Typography sx={{ fontSize: 20, color: "#ddd" }}>Edit Expense</Typography>
                </Grid>
            </Grid>
            <Box sx={{ m: 5 }} />
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container spacing={2}>
                        <Grid item md={2} xs={6}>
                            <MobileDatePicker
                                label="Date *"
                                inputFormat="DD/MM/YYYY"
                                value={dateValue}
                                onChange={(newValue: any) => {
                                    setDateValue(newValue);
                                }}
                                renderInput={(params: any) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                        <Grid item md={2} xs={6}>
                            <TimePicker
                                label="Time *"
                                inputFormat="H:mm"
                                value={timeValue}
                                onChange={(newValue: any) => {
                                    setTimeValue(newValue);
                                }}
                                renderInput={(params: any) => <TextField fullWidth {...params} />}
                            />
                        </Grid>
                    </Grid>
                </LocalizationProvider>
                <Box sx={{ m: 4 }} />
                <Grid container spacing={2}>
                    <Grid item md={2} xs={12}>
                        <TextField
                            autoComplete="off"
                            id="odometer"
                            type="number"
                            label="Odometer *"
                            InputLabelProps={{
                                shrink: true
                            }}
                            placeholder="Odometer"
                            {...register("odometer", { required: true })}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid item md={3} xs={6}>
                    <Typography sx={{ ml: 1, fontSize: 12, mt: 1, color: "gray" }}>Last odometer: {settingsVehicleSelected?.vehicle.last_odometer === null ? 0 : settingsVehicleSelected?.vehicle.last_odometer} km</Typography>
                </Grid>
                <Box sx={{ m: 3 }}></Box>
                <Grid container spacing={2}>
                    <Grid item md={10} xs={20}>
                        <SelectableFilterWithValue
                            title="Types of expenses"
                            resource="expenses-type"
                            source="expenses_type"
                            dataList={getListExpenseValues}
                            onEditItemSelected={expenseDetails?.expense_type_values}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 4 }}></Box>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={20}>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={15}>
                                    <Checkbox
                                        checked={checkedOtherOptions}
                                        edge="start"
                                        sx={{ display: "none" }}
                                    />
                                    <Button
                                        startIcon={checkedOtherOptions ? <RemoveIcon /> : <AddIcon />}
                                        onClick={() => { setCheckedOtherOptions((check: any) => !check) }}
                                        sx={{
                                            color: "gray",
                                            fontSize: 15,
                                            pl: 2,
                                            pr: 2
                                        }}
                                    >
                                        More Options
                                    </Button>
                                </Grid>
                            </Grid>
                            {checkedOtherOptions &&
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item md={5} xs={12}>
                                            <TextField
                                                autoComplete="off"
                                                sx={{ mt: 4 }}
                                                id="notes"
                                                label="Notes"
                                                placeholder="Notes (Optional)"
                                                {...register("notes", { required: false })}
                                                variant="outlined"
                                                multiline
                                                rows={4}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ m: 4 }}></Box>
                <Grid container spacing={2}>
                    <Grid item md={1} xs={3}>
                        <Button
                            variant="text"
                            size="large"
                            onClick={() => navigate("/expenses")}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item md={1} xs={4}>
                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            fullWidth
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )

}

export default Edit;