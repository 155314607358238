import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useGetOne } from 'react-admin';
import jwt_decode from "jwt-decode";

interface Props {
    showemail?: boolean;
    fontsize?: number;
}

const InfoAccount: FC<Props> = ({ showemail = true, fontsize }) => {

    const token = localStorage.getItem("access_token");
    let decode: any = jwt_decode(`${token}`);
    let user_id = decode['user']['id'];

    const { data: users, isLoading: isLoadingUsers, error: errorUsers } = useGetOne('users', { id: user_id });

    return (
        <Box>
            {users &&
                <>
                    <Typography fontSize={fontsize ? fontsize : 20} fontWeight="bold" sx={{ mt: 1 }}>{users?.firstname + " " + users?.lastname}</Typography>
                    {showemail &&
                        <Typography fontSize={15} color="gray">{users?.email}</Typography>
                    }
                </>
            }
        </Box>
    );

};

export default InfoAccount;