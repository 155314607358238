import * as React from 'react';
import { useNotify, useRefresh, useGetList, useGetOne, useDataProvider, useTheme } from 'react-admin';
import { Box, Stack, useMediaQuery, Theme } from '@mui/material';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import InsightsIcon from '@mui/icons-material/Insights';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import HelpIcon from '@mui/icons-material/Help';
import AssistantIcon from '@mui/icons-material/Assistant';
import PaidIcon from '@mui/icons-material/Paid';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import RouteIcon from '@mui/icons-material/Route';
import AddTaskIcon from '@mui/icons-material/AddTask';
import HomeIcon from '@mui/icons-material/Home';

const TimelineLarge = () => {

    const isMedium = useMediaQuery('(max-width:1800px)');

    const { data: pitstop, total, isLoading: isLoadingPitstop, error: errorPitstop } = useGetList(
        'history/pitstop',
        {
            pagination: { page: 1, perPage: isMedium ? 6 : 11 },
            sort: { field: 'date', order: 'DESC' }
        }
    );

    return (
        <Box>
            {total as number > 0 ?
                <Stack
                    direction="row"
                    divider={
                        <Box sx={{ backgroundColor: "#222", height: 15, width: 80, alignSelf: "center" }} />
                    }
                    spacing={0}
                    sx={{ pt: 0.5 }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 50,
                            width: 50,
                            backgroundColor: "#222",
                            p: 2,
                            borderRadius: 20
                        }}
                    >
                        <HomeIcon sx={{ fontSize: 35, color: "#f04e55" }} />
                    </Box>
                    {pitstop?.map((record) => {
                        return (
                            <Box
                                key={record.id}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: 50,
                                    width: 50,
                                    backgroundColor: "#222",
                                    cursor: "pointer",
                                    p: 2,
                                    borderRadius: 20
                                }}
                            >
                                {record.refueling ? (
                                    <LocalGasStationIcon sx={{ fontSize: 22, color: "#FFA000" }} />
                                ) : record.service ? (
                                    <MinorCrashIcon sx={{ fontSize: 22, color: "#C0CA33" }} />
                                ) : record.expenses ? (
                                    <AccountBalanceWalletIcon sx={{ fontSize: 22, color: "#00838F" }} />
                                ) : record.incomings ? (
                                    <PaidIcon sx={{ fontSize: 22 }} />
                                ) : record.route ? (
                                    <RouteIcon sx={{ fontSize: 22, color: "yellow" }} />
                                ) : record.reminders ? (
                                    <NotificationAddIcon sx={{ fontSize: 22, color: "green" }} />
                                ) : record.tasks ? (
                                    <AddTaskIcon sx={{ fontSize: 22, color: "gray" }} />
                                ) : null}
                            </Box>
                        )
                    })}
                </Stack>
                :
                <Stack
                    direction="row"
                    divider={
                        <Box sx={{ backgroundColor: "#222", height: 15, width: 80, alignSelf: "center" }} />
                    }
                    spacing={0}
                    sx={{ pt: 0.5 }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 50,
                            width: 50,
                            backgroundColor: "#222",
                            p: 2,
                            borderRadius: 20
                        }}
                    >
                        <HomeIcon sx={{ fontSize: 35, color: "#f04e55" }} />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 50,
                            width: 50,
                            backgroundColor: "#222",
                            p: 2,
                            borderRadius: 20
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 50,
                            width: 50,
                            backgroundColor: "#222",
                            p: 2,
                            borderRadius: 20
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 50,
                            width: 50,
                            backgroundColor: "#222",
                            p: 2,
                            borderRadius: 20
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 50,
                            width: 50,
                            backgroundColor: "#222",
                            p: 2,
                            borderRadius: 20
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 50,
                            width: 50,
                            backgroundColor: "#222",
                            p: 2,
                            borderRadius: 20
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 50,
                            width: 50,
                            backgroundColor: "#222",
                            p: 2,
                            borderRadius: 20
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 50,
                            width: 50,
                            backgroundColor: "#222",
                            p: 2,
                            borderRadius: 20
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 50,
                            width: 50,
                            backgroundColor: "#222",
                            p: 2,
                            borderRadius: 20
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 50,
                            width: 50,
                            backgroundColor: "#222",
                            p: 2,
                            borderRadius: 20
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 50,
                            width: 50,
                            backgroundColor: "#222",
                            p: 2,
                            borderRadius: 20
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 50,
                            width: 50,
                            backgroundColor: "#222",
                            p: 2,
                            borderRadius: 20
                        }}
                    />
                </Stack>
            }
        </Box>
    )
}

export default TimelineLarge;