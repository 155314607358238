import React, { FC, useState, useEffect, useCallback } from 'react';
import { useNotify, useRefresh, useCreate, useGetOne } from 'react-admin';
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { useMediaQuery, Theme, CircularProgress } from '@mui/material';
import SelectableFilter from '../../components/inputs/SelectableFilter';

const CreateSubscription: FC = () => {

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

    const navigate = useNavigate();
    const notify = useNotify();

    const [create, { isLoading, error, data }] = useCreate();

    const [companyItem, selectedCompanyItem] = useState<any>({});

    const selectedCompany = (data: any) => {
        selectedCompanyItem(data);
    }

    const [planItem, selectedPlanItem] = useState<any>({});

    const selectedPlan = (data: any) => {
        selectedPlanItem(data);
    }

    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = async (data: any) => {
        data.settings_id = companyItem.id
        data.plan_id = planItem.id

        if (planItem.id && companyItem.id) {
            create('subscriptions/create/custom', { data: data });
        } else {
            notify("Please choose company and plan to add new subscription", { type: "info" });
        }

    };

    if (data) {
        notify("Your subscription added successfully", { type: "success" });
        navigate("/subscriptions");
    }

    if (error) {
        notify("Your subscription was not added successfully", { type: "error" });
    }

    return (
        <Box sx={{ pt: 6 }}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={8}>
                    <Typography sx={{ fontSize: 20, color: "#ddd", mb: 2 }}>Add Subscription</Typography>
                </Grid>
            </Grid>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Typography color="gray" fontSize={14}>Choose company to assign a new subscription</Typography>
                <Grid container spacing={2}>
                    <Grid item md={2} xs={8}>
                        <SelectableFilter resource="settings" title="Assign to company" source="name" selected={selectedCompany} />
                    </Grid>
                </Grid>
                <Typography color="gray" mt={3} fontSize={14}>Choose your custom plan</Typography>
                <Grid container spacing={2}>
                    <Grid item md={2} xs={8}>
                        <SelectableFilter resource="plans" title="Choose plan" source="name" selected={selectedPlan} />
                    </Grid>
                </Grid>
                <Box sx={{ m: 4 }}></Box>
                <Grid container spacing={2}>
                    <Grid item md={1} xs={3}>
                        <Button
                            variant="text"
                            size="large"
                            onClick={() => navigate("/subscriptions")}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item md={1} xs={4}>
                        <Button
                            variant="contained"
                            type="submit"
                            size="large"
                            fullWidth
                        >
                            {isLoading && (
                                <CircularProgress
                                    sx={{ mr: 1.5 }}
                                    size={25}
                                    thickness={2}
                                />
                            )}
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )

}

export default CreateSubscription;